<template>
  <div>
    <v-card class="mb-2">
      <v-card-title>Вы набрали баллов: {{ myPoints }}</v-card-title>
    </v-card>
    <v-divider></v-divider>
    <v-sheet v-if="false">
      <v-tabs v-model="tabIndex">
        <v-tabs-slider color="secondary"></v-tabs-slider>
        <v-tab v-for="tab in tabs" :key="`tab-${tab.id}`">
          {{ tab.title }}
        </v-tab>
      </v-tabs>
      <v-simple-table v-if="tabIndex === 0 && validItems" dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Место</th>
              <th class="text-left"></th>
              <th class="text-left">Игрок</th>
              <th class="text-left">Регион</th>
              <th class="text-left">Баллы</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in validItems"
              :class="{
                warning: item.gameId === myGameId,
              }"
              :key="item.index"
            >
              <td>{{ item.index }}</td>
              <td class="align--center">
                <v-img
                  :src="item.image"
                  :width="50"
                  :height="50"
                  round
                  class="ma-2"
                  :style="{ borderRadius: '50%' }"
                />
              </td>
              <td>{{ item.user }}</td>
              <td>{{ item.region }}</td>
              <td>{{ item.points }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-simple-table v-if="tabIndex === 1 && standingsRegions" class="mt-4">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Место</th>
              <th class="text-left">Регион</th>
              <th class="text-left">Баллов</th>
              <th class="text-left">Игроков</th>
              <th class="text-left">Баллы в среднем на игрока</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in standingsRegions"
              :class="{
                warning: item.gameId === myGameId,
              }"
              :key="`${item.id}`"
            >
              <td>{{ index + 1 }}</td>
              <td>{{ item.region }}</td>
              <td>{{ item.points }}</td>
              <td>{{ item.count }}</td>
              <td style="font-weight: bold">{{ item.average }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-sheet>
    <v-sheet v-else>
      <v-simple-table v-if="tabIndex === 0 && validItems" dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Место</th>
              <th class="text-left">Игрок</th>
              <th class="text-left">Регион</th>
              <th class="text-left">Баллы</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="item in validItems"
              :class="{
                warning: item.gameId === myGameId,
              }"
              :key="item.index"
            >
              <td>{{ item.index }}</td>
              <td>{{ item.user }}</td>
              <td>{{ item.region }}</td>
              <td>{{ item.points }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-sheet>
  </div>
</template>

<script>
import groupBy from "lodash/groupBy";
import get from "lodash/get";
import keyBy from "lodash/keyBy";
import filter from "lodash/filter";
import map from "lodash/map";
import slice from "lodash/slice";
import reduce from "lodash/reduce";
import orderBy from "lodash/orderBy";

const _ = {
  groupBy,
  get,
  keyBy,
  filter,
  map,
  slice,
  reduce,
  orderBy,
};

export default {
  name: "ProNasStandings",
  props: ["items", "my-points", "my-game-id"],
  data() {
    return {
      tabIndex: 0,
      tabs: [
        { id: "personal", title: "Личный зачет - ТОП 100" },
        { id: "regions", title: "Регионы" },
      ],
    };
  },
  computed: {
    validItems() {
      return _.filter(this.items, (item) => item.points > 0);
    },
    standingsRegions() {
      const regions = _.reduce(
        this.validItems,
        (acc, item) => {
          const { region, points } = item;
          if (acc[region] == null) {
            acc[region] = {
              points: 0,
              count: 0,
            };
          }
          acc[region].points += points;
          acc[region].count += 1;
          return acc;
        },
        {}
      );

      const result = Object.keys(regions).map((region) => {
        const { points, count } = regions[region];
        return { region, points, count, average: Math.round(points / count) };
      });

      const sortedResult = _.orderBy(result, ["average"], ["desc"]);

      return sortedResult;

      // return _.orderBy(
      //   _.map(this.regions, (region) => {
      //     const { id: regionId } = region;
      //     const points = _.reduce(
      //       this.itemsByRegion[regionId],
      //       (total, item) => total + item.points,
      //       0
      //     );
      //     const usersCount =
      //       (this.itemsByRegion[regionId] &&
      //         this.itemsByRegion[regionId].length) ||
      //       0;
      //     const avg = Math.round(usersCount === 0 ? 0 : points / usersCount);
      //     return {
      //       ...region,
      //       points: avg,
      //       usersCount,
      //     };
      //   }),
      //   ["points"],
      //   ["desc"]
      // );
    },
  },
};
</script>
