<template>
  <app-event event-id="pro-nas" auth="login-only" :mapping="mapping">
    <template v-slot:logo>
      <v-img :src="require('./assets/logo-inverted.png')" class="ma-4" />
    </template>
  </app-event>
</template>

<script>
import AppEvent from "@/components/event/Event.vue";
import MusicChart from "./music";
import Quest from "./Quest";

export default {
  name: "ProNasPageEvent",
  components: {
    AppEvent,
  },
  data() {
    return {
      mapping: {
        chart: MusicChart,
        quest: Quest,
      },
    };
  },
};
</script>
