<template>
  <div>
    <v-card v-for="item in items" :key="`${item.id}`" class="mb-4">
      <v-card-title>{{ item.title }}</v-card-title>
      <v-card-text>
        <audio :src="item.url" controls></audio>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          id: "music-westbam",
          title: "Westbam - Hard Times",
          url: "https://cdn.your-quest.com/integrity-2021/music/westbam.mp3",
        },
        {
          id: "music-andrey-toronto-russians",
          title: "Andrey Toronto - Русские Богатыри",
          url:
            "https://cdn.your-quest.com/integrity-2021/music/andrey-toronto-russians.mp3",
        },
        {
          id: "music-connor-mothing-compares-2u",
          title: "Sinéad O'Connor - Nothing Compares 24U",
          url:
            "https://cdn.your-quest.com/integrity-2021/music/connor-mothing-compares-2u.mp3",
        },
        {
          id: "music-daft-punk-harder-better-faster",
          title: "Daft Punk - Harder, Better, Faster",
          url:
            "https://cdn.your-quest.com/integrity-2021/music/daft-punk-harder-better-faster.mp3",
        },
        {
          id: "music-depeche-mode-never",
          title: "Depeche Mode - Never Let Me Down Again",
          url:
            "https://cdn.your-quest.com/integrity-2021/music/depeche-mode-never.mp3",
        },
        {
          id: "music-dynoro-in-my-mind",
          title: "Dynoro & Gigi D'Agostino - In My Mind",
          url:
            "https://cdn.your-quest.com/integrity-2021/music/dynoro-in-my-mind.mp3",
        },
        {
          id: "music-ennio-morricone-chi mai",
          title: "Ennio Morricone - Chi Mai",
          url:
            "https://cdn.your-quest.com/integrity-2021/music/ennio-morricone-chi mai.mp3",
        },
        {
          id: "music-jack-koden-get-lucky",
          title: "Jack Koden - Get Lucky",
          url:
            "https://cdn.your-quest.com/integrity-2021/music/jack-koden-get-lucky.mp3",
        },
        {
          id: "music-kenny-g-havana",
          title: "Kenny G - Havana",
          url:
            "https://cdn.your-quest.com/integrity-2021/music/kenny-g-havana.mp3",
        },
        {
          id: "music-lunch-money",
          title: "LanchMoney Lewis - Bills",
          url:
            "https://cdn.your-quest.com/integrity-2021/music/lauch-money.mp3",
        },
        {
          id: "music-lesha-jay-autozvuk-bolesn",
          title: "Леша Джей - Автозвук Болезнь",
          url:
            "https://cdn.your-quest.com/integrity-2021/music/lesha-jay-autozvuk-bolesn.mp3",
        },
        {
          id: "music-lyapis-tanzuy",
          title: "Ляпис Трубецкой - Танцуй",
          url:
            "https://cdn.your-quest.com/integrity-2021/music/lyapis-tanzuy.mp3",
        },
        {
          id: "music-metallica-enter-sandman",
          title: "Metallica - Enter Sandman",
          url:
            "https://cdn.your-quest.com/integrity-2021/music/metallica-enter-sandman.mp3",
        },
        {
          id: "music-oblomov-jivi",
          title: "Вася Обломов - Живи",
          url:
            "https://cdn.your-quest.com/integrity-2021/music/oblomov-jivi.mp3",
        },
        {
          id: "music-prodigy-diesel-power",
          title: "Prodigy - Diesel Power",
          url:
            "https://cdn.your-quest.com/integrity-2021/music/prodigy-diesel-power.mp3",
        },
        {
          id: "music-slimus-aurus",
          title: "SLIMUS - Аурус",
          url:
            "https://cdn.your-quest.com/integrity-2021/music/slimus-aurus.mp3",
        },
        {
          id: "music-speeding-cars",
          title: "Speeding Cars - Walking On Cars",
          url:
            "https://cdn.your-quest.com/integrity-2021/music/speeding-cars.mp3",
        },
        {
          id: "music-system-of-a-down",
          title: "System Of A Down - Roulette",
          url:
            "https://cdn.your-quest.com/integrity-2021/music/system-of-a-down.mp3",
        },
      ],
    };
  },
};
</script>
